import React from 'react';
import { graphql } from 'gatsby';
import { InformationBox, InfoboxColumn, InfoboxSection } from './information-box';

const park = process.env.GATSBY_PARK;

export const InfoboxWildlife = (query) => {
  // Change the intro to the native name depending on the area
  const nativeName = park === 'uktnp' ? 'Pitjantjatjara/Yankunytjatjara name' : 'We say';

  // If no fields are set, don't render the component
  return !query.field_field_native_name && !query.field_scientific_name ? (
    <React.Fragment key={query.id}></React.Fragment>
  ) : (
    <InformationBox>
      {/* Column 1 */}
      <InfoboxColumn>
        {query.field_scientific_name?.value && (
          <InfoboxSection sectionTitle="Scientific name">
            <p>
              <em>{query.field_scientific_name?.value}</em>
            </p>
          </InfoboxSection>
        )}
        {query.field_native_name && (
          <InfoboxSection sectionTitle={nativeName}>
            <p>{query.field_native_name}</p>
          </InfoboxSection>
        )}
      </InfoboxColumn>
    </InformationBox>
  );
};

export const query = graphql`
  fragment InformationBoxWildlife on paragraph__information_wildlife {
    id
    drupal_id
    internal {
      type
    }
    field_native_name
    field_scientific_name {
      value
    }
    paragraph_type {
      drupal_internal__target_id
    }
  }
`;
